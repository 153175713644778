import React from 'react';
import __ from 'core/lib/localization';

import { ChapterCatalogData, UnitCatalogData } from 'core/models/catalog';
import { Story } from '@core/models/story-manager/story';
import { MarkSimple } from '@naan/primitives/text';
import { hashCode } from '@utils/string-utils';
import { styled } from '@naan/stitches.config';
import { NoLink } from '@naan/primitives/text/no-link';
import { bugsnagNotify } from '@app/notification-service';

const Wrapper = styled('section', {
  borderTop: '1px solid $colors$gray-100',
  padding: '24px 0 0',
  marginBottom: '80px',

  '@media print': {
    marginTop: '0',
    borderTop: 'none',
    paddingTop: '0',
    pageBreakBefore: 'always',
  },

  '@media print, screen and (min-width: 900px)': {
    display: 'flex',
  },

  '& .heading': {
    font: 'var(--large-heading-font)',
    marginBottom: '56px',
    '@media print, screen and (min-width: 900px)': {
      flex: '4',
    },
  },

  '& .outline': {
    marginTop: '8px',

    '& div': {
      breakInside: 'avoid-column',
    },

    '& h3': {
      font: 'var(--large-heading-font)',
      paddingBottom: '24px',
    },

    '@media print, screen and (min-width: 700px)': {
      columnCount: '2',
      columnGap: '32px',
      flex: '8',
    },

    '& ul': {
      breakInside: 'avoid-column',
    },

    '& li': {
      margin: '0 0 12px',
      padding: '0',
    },

    '& .chapter-title': {
      font: 'var(--medium-heading-font)',
      display: 'block',
      lineHeight: '28px',
      marginBottom: '12px',
      paddingLeft: '32px',
      position: 'relative',
      color: 'inherit',

      '& .chapter-position': {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '32px',
      },
    },

    '& ul ul': {
      marginBottom: '32px',
      lineHeight: '24px',
      paddingLeft: '32px',
    },

    '& ul ul li': {
      lineHeight: '24px',
    },
    '& ul ul li a': {
      font: 'var(--body-font)',
      color: '$colors$blue-500',
    },
  },
});

const goToFragment = (id: string) => (e: { preventDefault: () => void }) => {
  e.preventDefault();
  const element = document.getElementById(id);
  if (element) {
    const top = element.offsetTop - 56;
    window.scrollTo({ top, behavior: 'smooth' });
    /* element.scrollIntoView({ behavior: 'smooth', block }); */
  }
};

const ChapterOutline = ({ chapter }: { chapter: ChapterCatalogData }) => {
  if (chapter.passages === undefined) {
    bugsnagNotify(
      `missing passages data - please reingest - ${chapter.storySlug}`
    );
    return null;
  }
  return (
    <ul>
      {chapter.passages.map(passage => {
        const id = passage.id || String(hashCode(passage.hint)); // fallback until all catalog data reingested
        return (
          <li key={id}>
            <NoLink fragment={id} onClick={goToFragment(id)}>
              <MarkSimple source={passage.hint} />
            </NoLink>
          </li>
        );
      })}
    </ul>
  );
};

const UnitItem = ({ unit }: { unit: UnitCatalogData }) => {
  return (
    <>
      {unit.chapters.map((chapter: ChapterCatalogData) => {
        const id = `chapter-${chapter.sortingRef}`;

        return (
          <ul key={`outline-${id}`}>
            <li>
              <NoLink
                fragment={id}
                className="chapter-title"
                onClick={goToFragment(id)}
              >
                <span className="chapter-position">{chapter.position}</span>
                <MarkSimple source={chapter.title} />
              </NoLink>
              <ChapterOutline chapter={chapter} />
            </li>
          </ul>
        );
      })}
    </>
  );
};

export const StoryOutline = ({ story }: { story: Story }) => {
  (window as any).currentStory = story;
  return (
    <Wrapper>
      <h2 className="heading">{__('Story outline', 'storyOutline')}</h2>
      <div className="outline">
        {story.units.map(unit => {
          return (
            <div key={unit.slug}>
              {story.multiUnit && <h3>{unit.partLabel}</h3>}
              <UnitItem unit={unit} />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';
import { styled } from 'naan/stitches.config';
import { Portal } from 'naan/primitives/portal';

const ToastWrapper = styled('div', {
  position: 'fixed',
  left: '0',
  bottom: '0',
  paddingBottom: '$4',
  zIndex: 100,
});

type ToastWrapperProps = React.ComponentProps<typeof ToastWrapper>;

export const ToastTarget: React.FC<
  React.PropsWithChildren<
    {
      showNotification: boolean;
    } & Pick<ToastWrapperProps, 'css'>
  >
> = observer(({ showNotification, children, css }) => {
  return (
    <Portal>
      <ToastWrapper css={css}>
        <AnimatePresence>
          {showNotification && (
            /* @ts-expect-error */
            <motion.div
              initial={{ y: '100%' /* opacity: 0 */ }}
              animate={{ y: 0 /* opacity: 1 */ }}
              transition={{ ease: 'easeOut', duration: 0.3 }}
              exit={{ y: '100%' /* opacity: 0 */ }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </ToastWrapper>
    </Portal>
  );
});

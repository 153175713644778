import React from 'react';
import { FullyStyledMarkdown } from '@naan/primitives/text';
import { ChapterModel } from '../cali/script-model';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  marginBottom: '24px',

  '& .notes': {
    '@media print, screen and (min-width: 700px)': {
      display: 'flex',
      flex: '9',
    },

    backgroundColor: '$colors$yellow-200',
    borderRadius: '$radii$medium',
    padding: '16px 0',

    '& > h3': {
      flex: '1',
      padding: '0 16px',
      font: 'var(--compact-script-font)',
      fontStyle: 'italic',
    },
  },

  '& .note': {
    padding: '0 16px',
    flex: '8',

    '@media print, screen and (min-width: 700px)': {
      paddingLeft: '0',
    },

    '& > .content': {
      marginBottom: '24px /** space between multiple notes */',

      '&:last-child': {
        marginBottom: '0',
      },

      '& p': {
        font: 'var(--compact-script-font)',
      },

      '& strong': {
        fontWeight: '600',
      },
    },
  },

  '& .spacer': {
    flex: '3',
  },
});

export const Notes = ({ chapterModel }: { chapterModel: ChapterModel }) => {
  const { chapterNotes } = chapterModel;
  if (chapterNotes.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <div className="notes">
        <h3>{__('Notes', 'notes')}</h3>
        <div className="note">
          {chapterNotes.map(note => {
            // const text = note.head
            //   ? __('**%{head}—** %{body}', 'storyGuide.noteMarkdown', note)
            //   : note.body;

            return (
              <div className="content" key={note.key}>
                <FullyStyledMarkdown source={note.body} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="spacer" />
    </Wrapper>
  );
};

import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { styled } from 'naan/stitches.config';

const AnimatedTagsStyledContainer = styled(motion.div, { overflow: 'hidden' });

type AnimatedTagsContainerProps = {
  show: boolean;
  children: React.ReactNode;
};

export const AnimatedTagsContainer = React.memo(
  ({ show, children }: AnimatedTagsContainerProps) => {
    return (
      <AnimatePresence>
        {show ? (
          /* @ts-expect-error */
          <AnimatedTagsStyledContainer
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
            exit={{ height: 0 }}
          >
            {children}
          </AnimatedTagsStyledContainer>
        ) : null}
      </AnimatePresence>
    );
  }
);

import * as React from 'react';
import { ElementNode, Paragraph } from '@tikka/client/client-aliases';
import classNames from 'classnames';
import { MarkSimple } from '@naan/primitives/text';
import {
  ChapterModel,
  paragraphTranscript,
  paragraphTranslation,
} from 'components/guides/cali/script-model';
import { globalCss, styled } from '@naan/stitches.config';

const globalStyles = globalCss({
  '*': {
    margin: '0',
    padding: '0',
    border: 'none',
  },

  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },

  a: {
    textDecoration: 'none',
  },
  'ul li': {
    listStyle: 'none',
  },
});

const ChapterGlobalStyle: React.FC<React.PropsWithChildren<unknown>> = () => {
  globalStyles();
  return null;
};

const ChapterStyles = styled('div', {
  borderTop: '1px solid var(--gray100)',
  padding: '24px 0 0',
  marginBottom: '80px',

  '&.position--1': {
    pageBreakBefore: 'always',
    '@media print': {
      marginTop: '0',
      borderTop: 'none',
      paddingTop: '0',
    },
  },

  '& .heading': {
    font: 'var(--large-heading-font)',
    marginBottom: '56px',
  },

  '& .lines': {
    backgroundImage: 'repeating-linear-gradient(',

    backgroundSize: '42px 100%',

    marginBottom: '40px',

    '& .lines-content': {
      background: '$colors$white',

      '@media print, screen and (min-width: 700px)': {
        width: 'calc(50% + 16px)',
        paddingRight: '16px',
      },
    },
  },
});

const ParagraphStyles = styled('div', {
  '& .hint': {
    font: 'var(--medium-heading-font)',
    marginTop: '56px */',
    marginBottom: '24px',
    color: '$colors$blue-500',
  },

  '& .speaker': {
    font: 'var(--small-text-font)',
    padding: '4px 8px',
    border: '1px solid var(--gray100)',
    display: 'inline-block',
    borderRadius: '3px',
    margin: '4px 0 24px',
    color: 'var(--gray-5)',
  },

  '& .paragraph': {
    margin: '0 0 24px',
    breakInside: 'avoid-page',

    '@media print, screen and (min-width: 700px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '&.avoid-break': {
      breakInside: 'avoid-page',
    },

    '& > div': {
      '@media print, screen and (min-width: 700px)': {
        width: 'calc(50% - 16px)',
      },

      '&.original': {
        font: 'var(--compact-script-font)',
        marginBottom: '8px',
        '@media print, screen and (min-width: 700px)': {
          marginBottom: '0',
        },
      },

      '&.translation': {
        font: 'var(--body-font)',
        color: 'var(--text-secondary)',
        '& *': {
          color: 'inherit',
        },
      },
    },
  },
});

const SpeakerLabel = ({ node }: { node: ElementNode }) => {
  const element = node.element as Paragraph;
  const label = element.speakerLabel;
  if (!label) {
    return null;
  }

  return <h4 className={'speaker'}>{label}</h4>;
};

const ParagraphScript = ({ node }: { node: ElementNode }) => {
  // const hint = node.element.hint;
  const hint = 'node.element.hint'; // todo

  const transcript = paragraphTranscript(node);
  const translation = paragraphTranslation(node);

  return (
    <div className={classNames('paragraph', { 'avoid-break': hint })}>
      <div className="original">
        <MarkSimple source={transcript} />
      </div>
      <div className="translation">
        <MarkSimple source={translation} />
      </div>
    </div>
  );
};

export const BasicChapter = ({ model }: { model: ChapterModel }) => {
  const chapter = model.chapterData;

  return (
    <>
      <ChapterGlobalStyle />
      <ChapterStyles
        id={`chapter-${chapter.position}`}
        className={`position--${chapter.position}`}
      >
        <ParagraphStyles>
          {model.elementNodes.map(node => {
            if (node.element.kind === 'PARAGRAPH') {
              return (
                <React.Fragment key={node.element.id}>
                  <SpeakerLabel node={node} />
                  <ParagraphScript node={node} />
                </React.Fragment>
              );
            }
            return null; // ignore passages
          })}
        </ParagraphStyles>
      </ChapterStyles>
    </>
  );
};
